import { ProcessManagerFunction, ProcessManagerFunctionArgs } from "@lookiero/messaging";
import { QuizProjection } from "../../../projection/quiz/quiz";
import { viewQuizById } from "../../../projection/quiz/viewQuizById";
import { QuizCompleted } from "../model/quizCompleted";

const QUIZ_COMPLETED_EVENT = "QUIZ_COMPLETED";

interface EventManager {
  emit(eventType: string, payload?: unknown): void;
}

interface EmitQuizCompletedEventWhenQuizCompletedFunctionArgs extends ProcessManagerFunctionArgs {
  readonly eventManager: EventManager;
}

interface EmitQuizCompletedEventWhenQuizCompletedFunction
  extends ProcessManagerFunction<QuizCompleted, EmitQuizCompletedEventWhenQuizCompletedFunctionArgs> {}

const emitQuizCompletedEventWhenQuizCompleted: EmitQuizCompletedEventWhenQuizCompletedFunction =
  ({ eventManager, queryBus }) =>
  async ({ aggregateId }) => {
    const { quizNumber }: QuizProjection = await queryBus(viewQuizById({ id: aggregateId }));

    eventManager.emit(QUIZ_COMPLETED_EVENT, { quizId: aggregateId, quizNumber });
  };

export type { EmitQuizCompletedEventWhenQuizCompletedFunctionArgs };
export { emitQuizCompletedEventWhenQuizCompleted, QUIZ_COMPLETED_EVENT };
