import React, { FC, useMemo } from "react";
import { Spinner } from "@lookiero/aurora";
import { QueryStatus } from "@lookiero/messaging-react";
import { AnswersProvider } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Layout } from "../../../layout/Layout";
import { useViewQuizById } from "../../../projection/quiz/react/useViewQuizById";
import { useQuizId } from "../../components/organisms/question/behaviours/useQuizId";
import { useDefinition } from "../../hooks/useDefinition";
import { QuestionLayout } from "./components/questionLayout/QuestionLayout";

interface QuestionProps {
  readonly layout: Layout;
  readonly onBack?: () => void;
}

const Question: FC<QuestionProps> = ({ layout, onBack }) => {
  const [quizId] = useQuizId();
  const [quiz] = useViewQuizById({ id: quizId });
  const {
    definition: [definition, definitionStatus],
  } = useDefinition();

  const answers = useMemo(() => quiz?.answers || {}, [quiz?.answers]);

  const dependenciesLoaded = definitionStatus !== QueryStatus.LOADING && definition;

  if (!dependenciesLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <AnswersProvider answers={answers} questions={definition}>
      <QuestionLayout layout={layout} onBack={onBack} />
    </AnswersProvider>
  );
};

export { Question };
